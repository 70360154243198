import './App.css';
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import logo from './assets/logo.gif';
import gh_logo from './assets/github_logo.gif'
import discord_logo from './assets/discord_logo.gif'


function App() {
  const particlesInit = useCallback(async engine => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);

  return (
    <div className="App">

      <header className="App-header">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#000"
              }
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "push"
                },
                onHover: {
                  enable: true,
                  mode: "repulse"
                },
                resize: true
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40
                },
                push: {
                  quantity: 1,
                  size: 4
                },
                repulse: {
                  distance: 1,
                  duration: 0.4
                }
              }
            },
            particles: {
              color: {
                value: "#9e2994"
              },
              links: {
                color: "#9e2994",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
              },
              collisions: {
                enable: false
              },
              move: {
                direction: "random",
                enable: true,
                outMode: "bounce",
                random: true,
                speed: 2,
                straight: false
              },
              number: {
                density: {
                  enable: true,
                  value_area: 1200
                },
                value: 120
              },
              opacity: {
                value: 0.5
              },
              shape: {
                type: "triangle"
              },
              size: {
                random: true,
                value: 10
              }
            },
            detectRetina: true
          }}


        />

        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <img
            src={logo}
            style={{ width: "300px", height: "300px" }}
            alt="logo" />
          <div class="hero-container">
            <div class="environment"></div>
            <h2 class="hero glitch layers" data-text="10101010101010"><span>Cyphersoftware
            </span></h2>
          </div>

          <h3 style={{ color: "#fff" }}>Coming Soon</h3>
          <img
            src={gh_logo}
            style={{ width: "100px", height: "100px", marginRight: "10px", marginLeft: "10px" }}
            alt="logo"
            onClick={() => window.open("https://github.com/Cyphersoftware-uk")}
          />
          <img
            src={discord_logo}
            style={{ width: "110px", height: "100px", marginRight: "10px", marginLeft: "10px", marginTop: "10px"}}
            alt="logo"
            onClick={() => window.open("https://discord.gg/cyphersoftware")} />

        </div>
      </header>

    </div>
  );
}

export default App;
